import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Icon,
    Text,
    Badge,
    SlideFade,
    VStack,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    HStack,
    useBreakpointValue
} from "@chakra-ui/react";
import { BsArchiveFill, BsCheckCircleFill, BsFillTrashFill, BsPencil } from "react-icons/bs";
import { localDate } from "../../helpers/DayJsHelper";
import { useTable, useSortBy, usePagination } from "react-table";
import { getVersions } from "../../features/riskAssessments/riskAssessments.actions";

export const Versions = ({ riskAssessment, version }: any) => {
    const navigateTo = useNavigate();
    const isMobile = useBreakpointValue({ base: true, md: false });

    const [firstTimeLoading, setFirstTimeLoading] = useState(true);
    const [loading, setLoading] = useState<boolean>(true);
    // const [pageIndex, setPageIndex] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);

    const columns = useMemo(
        () => [
            {
                Header: "RAVID",
                accessor: "ravid",
                disableSortBy: true,
            },
            {
                Header: "VERSION",
                accessor: "ravsequencenumber",
                Cell: (cell: any) => <VStack alignItems="start">
                    <Text color="gray.600" fontSize="sm">
                        {cell.value || "-"}
                    </Text>
                </VStack>
            },
            {
                Header: "STATUS",
                accessor: "ravstate", //0 = Draft, 1 = Published, 2 = Archived, 99 = Deleted
                Cell: (cell: any) => <>
                    {cell.value === '0' && <Badge colorScheme={"orange"}><Icon
                        as={BsPencil}
                        mt="-2px"
                        mr="2px"
                        verticalAlign="middle"
                    /> Draft</Badge>}
                    {cell.value === '1' && <Badge colorScheme={"green"}><Icon
                        as={BsCheckCircleFill}
                        mt="-2px"
                        mr="2px"
                        verticalAlign="middle"
                    /> Current</Badge>}
                    {cell.value === '2' && <Badge colorScheme={"blue"}><Icon
                        as={BsArchiveFill}
                        mt="-2px"
                        mr="2px"
                        verticalAlign="middle"
                    /> Archived</Badge>}
                    {cell.value === '99' && <Badge colorScheme={"red"}><Icon
                        as={BsFillTrashFill}
                        mt="-2px"
                        mr="2px"
                        verticalAlign="middle"
                    /> Deleted</Badge>}
                    {cell.value !== '0' && cell.value !== '1' && cell.value !== '2' && cell.value !== '99' && <Badge colorScheme={"gray"}>Unknown</Badge>}
                </>
            },
            {
                Header: "CREATED",
                accessor: "ravdatecreated",
                Cell: (cell: any) => <Text
                    color="gray.600"
                    fontSize="sm"
                >
                    {localDate(cell.value)}
                </Text>
            },
            {
                Header: "NEXT REVIEW",
                accessor: "ravnextreviewdate",
                Cell: (cell: any) => <Text
                    color="gray.600"
                    fontSize="sm"
                >
                    {cell.value ? localDate(cell.value) : '-'}
                </Text>
            },
            {
                Header: "ACKNOWLEDGEMENTS",
                accessor: "acknowledgementcount",
                Cell: (cell: any) => <Text
                    color="gray.600"
                    fontSize="sm"
                >
                    {cell.value}
                </Text>
            }
        ], [riskAssessment]
    );

    function DataTable({
        data,
        columns,
        loading,
        pageCount: controlledPageCount,
        onHeaderClick
    }: any) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
        } = useTable(
            {
                columns,
                data,
                manualPagination: true,
                manualSortBy: true,
                pageCount: controlledPageCount,
                onHeaderClick,
                autoResetPage: false,
                initialState: {
                    hiddenColumns: ["ravid"]
                }
            },
            useSortBy,
            usePagination
        );

        return (
            <SlideFade in={!loading}>
                <VStack
                    display={{ base: "flex", lg: "none" }}
                    mb={currentItems.length > 6 ? 5 : 0}
                >
                    {
                        currentItems.map((task: any, i: number) => (
                            <Box
                                key={i}
                                w="100%"
                                className="hover-pop"
                                cursor="pointer"
                                onClick={() => {
                                    navigateTo(`/riskassessments/version/${task.ravid}`, {
                                        state: {
                                            raid: riskAssessment?.raid,
                                            ravid: task.ravid,
                                            resetTabs: true
                                        }
                                    });
                                }}
                            >
                                <Box
                                    w="100%"
                                    bg="green.500"
                                    roundedTop="md"
                                >
                                    <Text
                                        fontSize="xs"
                                        fontWeight={600}
                                        textAlign="start"
                                        color="white"
                                        p={0.5}
                                        mx={2}
                                    >
                                        Version {task.ravsequencenumber}
                                    </Text>
                                </Box>
                                <Box
                                    p={3}
                                    pt={2}
                                    w="100%"
                                    boxShadow="xs"
                                    roundedBottom="lg"
                                    border="1px solid"
                                    borderColor="gray.100"
                                >
                                    <VStack alignItems="start" >
                                        <HStack w="100%" gap={12}>
                                            <Box alignItems="start">
                                                <Text
                                                    fontSize="8pt"
                                                    fontWeight={600}
                                                    textAlign="start"
                                                    color="gray.600"
                                                >
                                                    Created
                                                </Text>

                                                <Text
                                                    fontSize="xs"
                                                    fontWeight={500}
                                                    textAlign="start"
                                                    color="gray.500"
                                                >
                                                    {localDate(task.ravdatecreated)}
                                                </Text>
                                            </Box>

                                            <Box alignItems="start">
                                                <Text
                                                    fontSize="8pt"
                                                    fontWeight={600}
                                                    textAlign="start"
                                                    color="gray.600"
                                                >
                                                    Next Review
                                                </Text>

                                                <Text
                                                    fontSize="xs"
                                                    fontWeight={500}
                                                    textAlign="start"
                                                    color="gray.500"
                                                >
                                                    {localDate(task.ravnextreviewdate) || "-"}
                                                </Text>
                                            </Box>
                                        </HStack>

                                        <HStack w="100%">
                                            <Badge
                                                fontSize="xs"
                                                colorScheme={task.acknowledgementcount === 100 ? "green" : "orange"}
                                            >
                                                {task.acknowledgementcount || 0} Acknowledgments
                                            </Badge>

                                            <>
                                                {task.ravstate === '0' && <Badge colorScheme={"orange"} fontSize="xs"><Icon
                                                    as={BsPencil}
                                                    mt="-2px"
                                                    mr="2px"
                                                    verticalAlign="middle"
                                                /> Draft</Badge>}
                                                {task.ravstate === '1' && <Badge colorScheme={"green"} fontSize="xs"><Icon
                                                    as={BsCheckCircleFill}
                                                    mt="-2px"
                                                    mr="2px"
                                                    verticalAlign="middle"
                                                /> Current</Badge>}
                                                {task.ravstate === '2' && <Badge colorScheme={"blue"} fontSize="xs"><Icon
                                                    as={BsArchiveFill}
                                                    mt="-2px"
                                                    mr="2px"
                                                    verticalAlign="middle"
                                                /> Archived</Badge>}
                                                {task.ravstate === '99' && <Badge colorScheme={"red"} fontSize="xs"><Icon
                                                    as={BsFillTrashFill}
                                                    mt="-2px"
                                                    mr="2px"
                                                    verticalAlign="middle"
                                                /> Deleted</Badge>}
                                                {
                                                    task.ravstate !== '0' &&
                                                    task.ravstate !== '1' &&
                                                    task.ravstate !== '2' &&
                                                    task.ravstate !== '99' && <Badge
                                                        colorScheme={"gray"}
                                                        fontSize="xs"
                                                    >
                                                        Unknown
                                                    </Badge>
                                                }
                                            </>
                                        </HStack>
                                    </VStack>
                                </Box>
                            </Box>
                        ))
                    }
                </VStack>

                <Table
                    {...getTableProps()}
                    id="TaskTable"
                    variant="striped"
                    display={{ base: "none", lg: "inline-table" }}
                >
                    <Thead>
                        {
                            headerGroups.map((headerGroup, i) => (
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map((column, i) => (
                                            <Th
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                onClick={() => onHeaderClick(column)}
                                                key={`task_header_${i}`}
                                            >
                                                <Text fontWeight={700} color="gray.600">
                                                    {column.render("Header")}
                                                </Text>
                                            </Th>
                                        ))
                                    }
                                </Tr>
                            ))
                        }
                    </Thead>

                    <Tbody {...getTableBodyProps()}>
                        {
                            page.map((row, i) => {
                                prepareRow(row);

                                return (
                                    <Tr
                                        {...row.getRowProps()}
                                        key={i}
                                        bg={i % 2 ? "white" : "purple.50"}
                                        borderBottom="1px solid"
                                        borderColor="gray.100"
                                        rounded="lg"
                                    >
                                        {
                                            row.cells.map((cell) => {
                                                if (cell.column.id === 'useractions') {
                                                    return (
                                                        <Td wordBreak="break-word" {...cell.getCellProps()}>
                                                            {cell.render("Cell")}
                                                        </Td>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <Td wordBreak="break-word"
                                                            className="hover-pop"
                                                            cursor="pointer"
                                                            onClick={() => {
                                                                navigateTo(`/riskassessments/version/${row.values.ravid}`, {
                                                                    state: {
                                                                        raid: riskAssessment?.raid,
                                                                        ravid: row.values.ravid,
                                                                        resetTabs: true
                                                                    }
                                                                });
                                                            }}
                                                            {...cell.getCellProps()}>
                                                            {cell.render("Cell")}
                                                        </Td>
                                                    );
                                                }
                                            })
                                        }
                                    </Tr>
                                );
                            })
                        }
                    </Tbody>
                </Table>

                {/* {
					navData.pageCount > 1 && <Box
						mt={3}
						textAlign="center"
					>
						<Pager
							pageCount={navData.pageCount || 0}
							pageIndex={navData.pageIndex || 0}
							setPageIndex={setPageIndex}
						/>
					</Box>
				} */}
            </SlideFade>
        );
    }

    const fetchData = useCallback(
        async (id: any) => {
            await getVersions(id)
                .then((res: any) => {
                    setCurrentItems(res.versions);
                })
                .catch((error: any) => {
                    console.log(error);
                });
        }, [riskAssessment]
    );

    useEffect(() => {
        if (!riskAssessment?.raid) {
            return;
        }

        setLoading(true);
        fetchData(riskAssessment?.raid).then(() => {
            setLoading(false);
            setFirstTimeLoading(false);
        });
    }, [riskAssessment]);

    return (
        <Box
            w="full"
            p={isMobile ? 0 : 3}
            border={isMobile ? "none" : "1px solid"}
            borderColor="gray.100"
            borderRadius="lg"
        >
            {
                currentItems && currentItems.length === 0 &&
                !loading && <Box
                    p={4}
                    textAlign="center"
                >
                    <Text color="gray.600">
                        No versions found
                    </Text>
                </Box>
            }

            {
                currentItems && DataTable({
                    data: currentItems ?? [],
                    columns,
                    loading,
                    pageCount: 0,
                    onHeaderClick: (c: any) => { }
                })
            }
        </Box>
    );
};