import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Avatar,
    Badge,
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    Spacer,
    Square,
    SkeletonText,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useBreakpointValue,
    VStack,
    Skeleton,
    SlideFade,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Textarea,
    Input,
    Tag,
    TagCloseButton,
    TagLabel,
} from "@chakra-ui/react";
import { BsArrowLeftCircle, BsArchive, BsCalendar2Week, BsCheckCircleFill, BsCheckCircle, BsExclamationCircle, BsHandThumbsUp, BsTrash, BsPencil, BsPeople, BsPlusCircle } from "react-icons/bs";

import { acknowledgeRiskAssessment, beginNewReview, getRiskAssessment, getVersion, updateRiskAssessment } from "../../features/riskAssessments/riskAssessments.actions";
import { localDate } from "../../helpers/DayJsHelper";

import { Acknowledgements } from "./Acknowledgements";
import { Versions } from "./Versions";
import { RootState } from "../../redux/rootReducer";
import { useSelector } from "react-redux";
import { HazardReader } from "./HazardReader";
import { isEmpty, isNull } from "lodash";

import miniLogoWhite from "../../assets/logos/obbi_logo_mini_white.png";
import hazardImg from "../../assets/images/incident.svg";

import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _ from "lodash";
import { getCurrentTeams } from "../../features/common/common.actions";
import { TeamMessageCombobox } from "../messages/TeamMessageCombobox";
import { CheckProductTierFlag } from "../../helpers/Utilities";

dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const RiskAssessment = () => {
    const navigateTo = useNavigate();
    const { state }: any = useLocation();

    const [tabIndex, setTabIndex] = useState(0);
    const [firstTimeLoading, setFirstTimeLoading] = useState(true);
    const [loading, setLoading] = useState<boolean>(true);

    const [riskAssessment, setRiskAssessment] = useState<any>({
        raid: null,
        raorganisation: null,
        raname: null,
        radescription: null,
        rastate: null,
        rapath: null,
        racategory: null,
        radatecreated: null,
        racreatedby: null,
        radatelastmodified: null,
        ralastmodifiedby: null,
        raexpirydate: null,
        raalertbeforeexpirynumber: null,
        raalertbeforeexpiryunit: null,
        raalertwhenexpired: null,
        raexpirystate: null,
        racurrentversion: null,
        raeditingteam: null,
        raonlymanagerscanedit: null,
        rapublishedversion: null,
        creatorforename: null,
        creatorsurname: null,
        modforename: null,
        modsurname: null,
        editingteamname: null,
        alertbeforeexpiry: null,
        alertwhenexpired: null,
        expired: null,
        expiring: null,
        currentversion: {
            ravid: null,
            ravriskassessment: null,
            ravorganisation: null,
            ravsequencenumber: null,
            ravstate: null,
            ravname: null,
            ravreviewdate: null,
            ravcarriedoutby: null,
            ravcarriedouttext: null,
            ravreviewnotes: null,
            ravnextreviewdate: null,
            ravpath: null,
            ravdatecreated: null,
            ravcreatedby: null,
            ravdatelastmodified: null,
            ravlastmodifiedby: null,
            creatorforename: null,
            creatorsurname: null,
            modforename: null,
            modsurname: null,
            hazards: []
        },
        publishedversion: {
            ravid: null,
            ravriskassessment: null,
            ravorganisation: null,
            ravsequencenumber: null,
            ravstate: null,
            ravname: null,
            ravreviewdate: null,
            ravcarriedoutby: null,
            ravcarriedouttext: null,
            ravreviewnotes: null,
            ravnextreviewdate: null,
            ravpath: null,
            ravdatecreated: null,
            ravcreatedby: null,
            ravdatelastmodified: null,
            ravlastmodifiedby: null,
            creatorforename: null,
            creatorsurname: null,
            modforename: null,
            modsurname: null,
            hazards: []
        },
        teams: [
            {
                ratriskassessment: null,
                ratteam: null,
                ratdatecreated: null,
                ratcreatedby: null,
                teamid: null,
                teamname: null,
                teamorganisation: null,
                teamparent: null,
                teamdatecreated: null,
                teamcreatedby: null,
                teamdatelastmodified: null,
                teamlastmodifiedby: null,
                teamleft: null,
                teamright: null,
                teampath: null,
                teamshortpath: null,
                teamindent: null
            }
        ]
    }
    );

    const [version, setVersion] = useState<any>({
        ravid: null,
        ravriskassessment: null,
        ravorganisation: null,
        ravsequencenumber: null,
        ravstate: null,
        ravname: null,
        ravreviewdate: null,
        ravcarriedoutby: null,
        ravcarriedouttext: null,
        ravreviewnotes: null,
        ravnextreviewdate: null,
        ravpath: null,
        ravdatecreated: null,
        ravcreatedby: null,
        ravdatelastmodified: null,
        ravlastmodifiedby: null,
        creatorforename: null,
        creatorsurname: null,
        modforename: null,
        modsurname: null,
        hazards: []
    });

    const [isDraftVersion, setIsDraftVersion] = useState(false);
    const [publishedVersionId, setPublishedVersionId] = useState(null);

    const [onEditingTeam, setOnEditingTeam] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    const [acknowledgement, setAcknowledgement] = useState<any>(null);

    const user = useSelector((state: RootState) => state.authReducer.user);
    const isManager = user?.isTeamManager;

    const isMobile = useBreakpointValue({ base: true, md: false });

    const hasRiskAssessmentEditingPermission = CheckProductTierFlag(user?.producttier?.ptriskassessmenteditingflag);

    const [acknowledgementTeams, setAcknowledgementTeams] = useState<any>([]);
    const [selectedAcknowledgementTeams, setSelectedAcknowledgementTeams] = useState<any>([]);

    const [date, setDate] = useState<any>("");

    const getRiskAssesssment = () => {
        setLoading(true);

        getVersion(state.raid, state.ravid)
            .then((res: any) => {
                setVersion(res.version);

                setIsDraftVersion(res?.version?.ravstate === '0');

                setOnEditingTeam(res.userCanEditRA)
                setCanEdit(res.userCanEditRA && (res?.version?.ravstate === '0' || res?.version?.ravstate === '1'));

                getRiskAssessment(res?.raId)
                    .then((res: any) => {
                        setRiskAssessment(res.riskAssessment);
                        setPublishedVersionId(res.riskAssessment?.publishedversion?.ravid);
                        setAcknowledgement(res.riskAssessment?.acknowledgement);

                        setSelectedAcknowledgementTeams(res.riskAssessment?.teams);

                        const reviewDate = dayjs(res.riskAssessment?.raexpirydate)?.format("YYYY-MM-DD");
                        setDate(reviewDate);
                    })
                    .catch((error: any) => {
                        console.log(error);
                    })
                    .finally(() => {
                        setLoading(false);
                        setFirstTimeLoading(false);
                    });
            })
            .catch((error: any) => {
                console.log(error);

                setLoading(false);
                setFirstTimeLoading(false);
            })
    };

    useEffect(() => {
        if (state.resetTabs) {
            setTabIndex(0);
        }

        getRiskAssesssment();
        getTeamsToAcknowledge();
    }, []);

    async function createNewReview() {
        try {
            await beginNewReview(riskAssessment?.raid)
                .then((res: any) => {
                    navigateTo(`/riskassessments/edit/${res.riskAssessment.raid}`);
                });
        } catch (error) {
            console.log(error);
        }
    }

    async function acknowledge() {
        try {
            await acknowledgeRiskAssessment(riskAssessment?.raid)
                .then(() => {
                    getRiskAssesssment();
                });
        } catch (error) {
            console.log(error);
        } finally {
            setIsAcknowledgeModalOpen(false);
        }
    }

    const getColor = () => version?.ravstate === '0' ? "orange.700" :
        version?.ravstate === '1' ? 'green.600' :
            version?.ravstate === '2' ?
                "blue.600" : "red.600";

    const [editBody, setEditBody] = useState<any>({
        title: "",
        description: "",
        category: "",
        teams: [],
        nextReviewDate: ""
    });

    const [isControlModalOpen, setIsControlModalOpen] = useState(false);
    const [isAcknowledgeModalOpen, setIsAcknowledgeModalOpen] = useState(false);

    const validateControlForm = () => {
        const putBody = {
            id: riskAssessment.raid,
            name: editBody.title || riskAssessment.raname,
            category: editBody.category || riskAssessment.racategory,
            description: editBody.description || riskAssessment.radescription,
            expirydate: date || riskAssessment.raexpirydate,
            expirynumber: riskAssessment.raalertbeforeexpirynumber,
            expiryunit: riskAssessment.raalertbeforeexpiryunit,
            expiryalert: riskAssessment.alertwhenexpired,
            teams: editBody.teams?.map((team: any) => team.teamid)
        };

        updateRiskAssessment(putBody)
            .then((res: any) => {
                setRiskAssessment(res?.riskAssessment);
                setSelectedAcknowledgementTeams(res.riskAssessment?.teams);
            })
            .catch((error: any) => {
                console.log(error);
            })
            .finally(() => {
                setIsControlModalOpen(false);
                getTeamsToAcknowledge();
            });
    };

    const getTeamsToAcknowledge = () => {
        getCurrentTeams()
            .then((res: any) => {
                let updatedTeams = res.manages;

                for (const team of selectedAcknowledgementTeams) {
                    updatedTeams = _.filter(
                        updatedTeams,
                        (t: any) => {
                            return t?.teamid !== team?.teamid
                        }
                    );
                }

                setAcknowledgementTeams(updatedTeams);
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const selectTeam = (arg: any): void => {
        if (arg.teamid === "0") return;

        let currentSelection = selectedAcknowledgementTeams;
        currentSelection.push(arg);

        let updatedTeams = _.filter(
            acknowledgementTeams,
            (team: any) => {
                return arg.teamid !== team.teamid;
            }
        );

        setAcknowledgementTeams(updatedTeams);
        setSelectedAcknowledgementTeams(currentSelection);

        setEditBody({
            ...editBody,
            teams: currentSelection
        });
    };

    const deselectTeam = (id: string): void => {
        let currentSelection = selectedAcknowledgementTeams;

        const deselectedTeam =
            _.filter(
                currentSelection,
                (team: any) => {
                    return id === team.teamid;
                }
            );

        currentSelection = _.filter(
            currentSelection,
            (team: any) => {
                return !deselectedTeam.includes(team)
            }
        );

        let updatedTeams = acknowledgementTeams;
        updatedTeams = updatedTeams.concat(deselectedTeam);
        updatedTeams = _.orderBy(updatedTeams, [team => team?.teamname?.toLowerCase()], ['asc']);

        setAcknowledgementTeams(updatedTeams);
        setSelectedAcknowledgementTeams(currentSelection);

        setEditBody({
            ...editBody,
            teams: currentSelection
        });
    };

    const renderAddControlModal = () => {
        return <Modal
            size="lg"
            isOpen={isControlModalOpen}
            onClose={() => {
                setIsControlModalOpen(false);

                setSelectedAcknowledgementTeams(riskAssessment?.teams);
                getTeamsToAcknowledge();
            }}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader color="gray.600">
                    Edit Risk Assessment Details
                </ModalHeader>

                <Box
                    m={5}
                    p={3}
                    bg="red.50"
                    border="1px solid"
                    borderColor="red.100"
                    borderRadius="lg"
                >
                    <Text
                        fontSize="sm"
                        fontWeight={500}
                        color="red.500"
                    >
                        This risk assessment has already been published and may have acknowledgments associated with it. Are you sure you want to make changes?
                    </Text>
                </Box>

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody>
                    <VStack
                        fontSize="sm"
                        alignItems="start"
                        gap={2}
                        w="full"
                    >
                        <FormControl>
                            <FormLabel
                                fontWeight={500}
                                fontSize="sm"
                                color="gray.600"
                            >
                                Risk Assessment Name
                            </FormLabel>

                            <Input
                                defaultValue={riskAssessment?.raname}
                                onChange={(e) => {
                                    setEditBody({
                                        ...editBody,
                                        title: e.target.value
                                    });
                                }}
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel
                                fontWeight={500}
                                fontSize="sm"
                                color="gray.600"
                            >
                                Category
                            </FormLabel>

                            <Input
                                defaultValue={riskAssessment?.racategory || ""}
                                onChange={(e) => {
                                    setEditBody({
                                        ...editBody,
                                        category: e.target.value
                                    });
                                }}
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel
                                fontWeight={500}
                                fontSize="sm"
                                color="gray.600"
                            >
                                Description
                            </FormLabel>

                            <Textarea
                                rows={5}
                                defaultValue={riskAssessment?.radescription || ""}
                                onChange={(e: any) => {
                                    setEditBody({
                                        ...editBody,
                                        description: e.target.value
                                    });
                                }}
                            />
                        </FormControl>

                        <FormControl
                            border="1px solid"
                            borderColor="gray.200"
                            rounded="lg"
                            p={3}
                        >
                            <FormLabel
                                fontWeight={500}
                                fontSize="sm"
                                color="gray.600"
                            >
                                Next Review Date
                            </FormLabel>

                            <HStack w="full" alignItems="end">
                                <VStack alignItems="start" gap={2}>
                                    <Text
                                        fontWeight={500}
                                        fontSize="xs"
                                        color="gray.500"
                                    >
                                        It is at your discretion to decide when a review is deemed necessary. However, it is recommended that Risk Assessments are reviewed on an annual basis.
                                    </Text>
                                    <HStack>
                                        {
                                            !isMobile && <Button
                                                size="sm"
                                                onClick={() => {
                                                    const sixMonthsFromNow = dayjs(
                                                        dayjs().add(dayjs.duration({ 'months': 6 }))
                                                    )
                                                        .format("YYYY-MM-DD");

                                                    setDate(sixMonthsFromNow);
                                                    setEditBody({
                                                        ...editBody,
                                                        expirydate: sixMonthsFromNow
                                                    });
                                                }}
                                            >
                                                6 Months
                                            </Button>
                                        }

                                        <Button
                                            size="sm"
                                            onClick={() => {
                                                const oneYearFromNow = dayjs(
                                                    dayjs().add(dayjs.duration({ 'years': 1 }))
                                                )
                                                    .format("YYYY-MM-DD");

                                                setDate(oneYearFromNow);
                                                setEditBody({
                                                    ...editBody,
                                                    expirydate: oneYearFromNow
                                                });
                                            }}
                                        >
                                            1 Year
                                        </Button>

                                        <Button
                                            size="sm"
                                            onClick={() => {
                                                const twoYearsFromNow = dayjs(
                                                    dayjs().add(dayjs.duration({ 'years': 2 }))
                                                )
                                                    .format("YYYY-MM-DD");

                                                setDate(twoYearsFromNow);
                                                setEditBody({
                                                    ...editBody,
                                                    expirydate: twoYearsFromNow
                                                });
                                            }}
                                        >
                                            2 Years
                                        </Button>

                                        <Button
                                            size="sm"
                                            onClick={() => {
                                                const threeYearsFromNow = dayjs(
                                                    dayjs().add(dayjs.duration({ 'years': 3 }))
                                                )
                                                    .format("YYYY-MM-DD");

                                                setDate(threeYearsFromNow);
                                                setEditBody({
                                                    ...editBody,
                                                    expirydate: threeYearsFromNow
                                                });
                                            }}
                                        >
                                            3 Years
                                        </Button>
                                    </HStack>
                                </VStack>
                            </HStack>

                            <Input
                                mt={2}
                                type="date"
                                value={date}
                                onKeyDown={(e: any) => { e.preventDefault() }}
                                onWheel={(e: any) => e.target.blur()}
                                onChange={(e: any) => {
                                    setDate(e.target.value);
                                    setEditBody({
                                        ...editBody,
                                        expirydate: e.target.value
                                    });
                                }}
                            />
                        </FormControl>

                        <FormControl
                            p={3}
                            border="1px solid"
                            borderColor="gray.200"
                            rounded="lg"
                        >
                            <VStack alignItems="start">
                                <FormLabel
                                    fontWeight={500}
                                    fontSize="sm"
                                    color="gray.600"
                                >
                                    Teams to Acknowledge
                                    
                                    <Badge
                                        alignSelf="baseline"
                                        size="sm"
                                        ml={2}
                                    >
                                        {(selectedAcknowledgementTeams.length).toString()}
                                    </Badge>
                                </FormLabel>

                                <Box>
                                    <Text
                                        fontWeight={500}
                                        fontSize="xs"
                                        color="gray.500"
                                    >
                                        The teams you select from the list are required to read and acknowledge this risk assessment.
                                    </Text>

                                    <Text
                                        mt={2}
                                        fontWeight={500}
                                        fontSize="xs"
                                        color="gray.500"
                                    >
                                        Teams you have responsibilities for appear in this list.
                                    </Text>
                                </Box>

                                <Box w="full">
                                    <TeamMessageCombobox
                                        teams={acknowledgementTeams}
                                        update={selectTeam}
                                        selectedTeam={{}}
                                    />
                                </Box>
                            </VStack>

                            {
                                selectedAcknowledgementTeams.length > 0 && <Box textAlign="left">
                                    {
                                        selectedAcknowledgementTeams?.map((team: any, i: number) => (
                                            <Tag
                                                key={`team_${i}`}
                                                size="md"
                                                mr={2}
                                                mt={3}
                                                borderRadius='lg'
                                                variant='solid'
                                                colorScheme='green'
                                            >
                                                <TagLabel>{team.teamname}</TagLabel>
                                                <TagCloseButton
                                                    onClick={() => {
                                                        deselectTeam(team.teamid)
                                                    }}
                                                />
                                            </Tag>
                                        ))
                                    }
                                </Box>
                            }
                        </FormControl>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <HStack>
                        <Button
                            cursor="pointer"
                            size="sm"
                            onClick={() => {
                                setIsControlModalOpen(false);

                                setSelectedAcknowledgementTeams(riskAssessment?.teams);
                                getTeamsToAcknowledge();
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            className="hover-pop"
                            colorScheme="brand"
                            size="sm"
                            _active={{ bg: "" }}
                            _hover={{ bg: "#248451" }}
                            onClick={() => { validateControlForm(); }}
                        >
                            Update
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    };

    const renderAcknowledgeModal = () => {
        return <Modal
            size="lg"
            isOpen={isAcknowledgeModalOpen}
            onClose={() => { setIsAcknowledgeModalOpen(false); }}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader color="gray.600">
                    Acknowledge Risk Assessment
                </ModalHeader>

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody>
                    <Text
                        fontSize="sm"
                        fontWeight={500}
                        color="gray.600"
                    >
                        By clicking confirm you acknowledge that you and read and fully understood this risk assessment.
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <HStack>
                        <Button onClick={() => { setIsAcknowledgeModalOpen(false); }} cursor="pointer" size="sm">
                            Cancel
                        </Button>

                        <Button
                            className="hover-pop"
                            colorScheme="brand"
                            size="sm"
                            _active={{ bg: "" }}
                            _hover={{ bg: "#248451" }}
                            onClick={acknowledge}
                        >
                            Confirm
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    };

    return (
        <Box m={{ base: 0, md: 5 }}>

            {/* navigation */}
            <Button
                display={{ base: "none", md: "unset" }}
                w="fit-content"
                size="sm"
                mb={2}
                variant="outline"
                color="gray.600"
                fontWeight={600}
                boxShadow="xs"
                onClick={() => {
                    navigateTo('/riskassessments');
                }}
            >
                <HStack>
                    <Icon as={BsArrowLeftCircle} />
                    <Text>Back to Risk Assessments</Text>
                </HStack>
            </Button>

            <Box
                h={{ base: "full", md: "unset" }}
                pt={5}
                pb={!isMobile && tabIndex !== 0 ? 3 : 0}
                px={{ base: 0, md: 5 }}
                bg="white"
                rounded={{ base: "none", md: "lg" }}
                roundedTop={!(isManager || canEdit) ? "lg" : "none"}
                boxShadow={{ base: "none", md: "lg" }}
            >
                <Tabs
                    mt={(isManager || canEdit) ? 0 : 3}
                    colorScheme="green"
                    onChange={(index) => setTabIndex(index)}
                    defaultIndex={0}
                    index={tabIndex}
                >
                    {
                        (isManager || canEdit) && <Flex
                            justify={{ base: "center", md: "space-between" }}
                            w={{ base: "full", md: "unset" }}
                            mx={{ base: 0, md: 0 }}
                            mb={{ base: 1, md: 2 }}
                        >
                            <TabList
                                ml={{ base: 0, md: 5 }}
                                w={{ base: "full", md: "unset" }}
                                fontSize={{ base: "xs", md: "md" }}
                                justifyContent="center"
                            >
                                <Tab fontSize={{ base: "xs", md: "sm" }}>
                                    Version
                                </Tab>

                                {
                                    !isDraftVersion && <Tab fontSize={{ base: "xs", md: "sm" }}>
                                        Acknowledgements
                                    </Tab>
                                }

                                {
                                    onEditingTeam && <Tab fontSize={{ base: "xs", md: "sm" }}>
                                        Version History
                                    </Tab>
                                }
                            </TabList>
                        </Flex>
                    }

                    <TabPanels>
                        <TabPanel
                            px={0}
                            pb={{
                                base: 0,
                                md: riskAssessment?.acknowledgementrequired ||
                                    acknowledgement?.raadateacknowledged != null ? 0 : 5
                            }}
                        >
                            <VStack alignItems="start" gap={1}>
                                <Box w="full">
                                    <Grid
                                        templateColumns={{ base: "repeat(1, 4fr)", md: "repeat(, 3fr)", lg: "repeat(4, 3fr)" }}
                                        gap={6}
                                        p={{ base: 3, md: 5 }}
                                        pt={(isManager || canEdit) ? 0 : 5}
                                        mt={{ base: -5, md: -6 }}
                                        mx={{ base: 0, md: -5 }}
                                        bgGradient='linear(to-r, green.500, green.400)'
                                        roundedTop={!(isManager || canEdit) ? "lg" : "none"}
                                    >
                                        {/* version warning */}
                                        {/* state - 0 = draft, 1 = published, 2 = archived */}
                                        {
                                            firstTimeLoading && canEdit ? <GridItem colSpan={4}>
                                                <Skeleton
                                                    rounded="lg"
                                                    style={{
                                                        width: "100%",
                                                        height: isMobile ? "100px" : "48px",
                                                        marginTop: isMobile ? "10px" : "0"
                                                    }}
                                                />
                                            </GridItem> :
                                                (canEdit || version?.ravstate === '2') && <GridItem colSpan={4}>
                                                    <Box
                                                        w="full"
                                                        borderRadius="md"
                                                        mt={{ base: 3, md: 0 }}
                                                        mb={{ base: -5, md: 0 }}
                                                        px={{ base: '4', md: '3' }}
                                                        py={{ base: '4', md: '2' }}
                                                        bg={
                                                            version?.ravstate === '0' ? "orange.50" :
                                                                version?.ravstate === '1' ? "green.50" :
                                                                    version?.ravstate === '2' ? "blue.50" : "red.50"
                                                        }
                                                    >
                                                        <Stack
                                                            direction={{ base: 'column', md: 'row' }}
                                                            justify="space-between"
                                                            spacing={{ base: '3', md: '2' }}
                                                        >
                                                            <Stack
                                                                spacing="4"
                                                                direction={{ base: 'column', md: 'row' }}
                                                                align={{ base: 'start', md: 'center' }}
                                                            >
                                                                {
                                                                    !isMobile && (
                                                                        <Square size="6" bg={version?.ravstate === '0' ?
                                                                            "orange.50" :
                                                                            version?.ravstate === '1' ?
                                                                                'green.50' :
                                                                                version?.ravstate === '2' ?
                                                                                    "blue.50" :
                                                                                    "red.50"
                                                                        }
                                                                            borderRadius="md">
                                                                            <Icon as={BsExclamationCircle} boxSize="6"
                                                                                color={
                                                                                    version?.ravstate === '0' ?
                                                                                        "orange.500" :
                                                                                        version?.ravstate === '1' ?
                                                                                            'green.500' :
                                                                                            version?.ravstate === '2' ?
                                                                                                "blue.500" :
                                                                                                "red.500"}
                                                                            />
                                                                        </Square>
                                                                    )
                                                                }

                                                                <Stack
                                                                    spacing="0.5"
                                                                    pe={{ base: '4', md: '0' }}
                                                                    fontWeight={500}
                                                                    color={getColor()}
                                                                >
                                                                    {
                                                                        version?.ravstate === '0' &&
                                                                        <Text fontSize={{ base: "sm", md: "sm" }} fontWeight="md">
                                                                            This version is a draft and has not been published. It will not be visible to users until it is published.
                                                                        </Text>
                                                                    }
                                                                    {
                                                                        version?.ravstate === '1' && riskAssessment?.currentversion != null && canEdit &&
                                                                        <Text fontSize={{ base: "sm", md: "sm" }} fontWeight="md">
                                                                            There is an ongoing review of this risk assessment, would you like to continue working on it?
                                                                        </Text>
                                                                    }
                                                                    {
                                                                        version?.ravstate === '1' && riskAssessment?.currentversion === null && canEdit &&
                                                                        <Text fontSize={{ base: "sm", md: "sm" }} fontWeight="md">
                                                                            This version is the current published version, would you like to start a new review of this version?
                                                                        </Text>
                                                                    }
                                                                    {
                                                                        version?.ravstate === '2' &&
                                                                        <Text fontSize={{ base: "sm", md: "sm" }} fontWeight="md">
                                                                            This version is an archived version and lists the hazards and controls that were in place at the time of archiving. It is not the current version of the risk assessment.
                                                                        </Text>
                                                                    }
                                                                    {
                                                                        version?.ravstate === '99' &&
                                                                        <Text fontSize={{ base: "sm", md: "sm" }} fontWeight="md">
                                                                            This version has been deleted and is no longer available.
                                                                        </Text>
                                                                    }
                                                                </Stack>
                                                            </Stack>

                                                            <Spacer />

                                                            {
                                                                publishedVersionId !== null &&
                                                                (version?.ravstate !== "0" && version?.ravstate !== "1") &&
                                                                <Stack
                                                                    direction={{ base: 'column', sm: 'row' }}
                                                                    spacing={3}
                                                                    align={{ base: 'stretch', md: 'center' }}
                                                                >
                                                                    <Button
                                                                        variant="outline"
                                                                        borderColor={version?.ravstate === '0' ? "orange.500" : version?.ravstate === '2' ? "blue.500" : "red.500"}
                                                                        bg="transparent"
                                                                        w="full"
                                                                        size="sm"
                                                                        color={getColor()}
                                                                        _hover={{ bg: "whiteAlpha.800" }}
                                                                        onClick={() => {
                                                                            navigateTo(`/riskassessments/version/${publishedVersionId}`, {
                                                                                state: {
                                                                                    raid: riskAssessment?.raid,
                                                                                    ravid: publishedVersionId
                                                                                }
                                                                            });
                                                                        }}
                                                                    >
                                                                        <HStack alignItems="center">
                                                                            <Icon
                                                                                as={BsArrowLeftCircle}
                                                                                color={
                                                                                    version?.ravstate === '0' ? "orange.500" :
                                                                                        version?.ravstate === '2' ? "blue.500" :
                                                                                            "red.500"
                                                                                }
                                                                            />
                                                                            <Text fontSize={{ base: "sm", md: "sm" }} fontWeight="md">
                                                                                Back to Current Published Version
                                                                            </Text>
                                                                        </HStack>
                                                                    </Button>
                                                                </Stack>
                                                            }

                                                            {
                                                                (version?.ravstate === '0' || version?.ravstate === '1') &&
                                                                riskAssessment?.currentversion != null &&
                                                                hasRiskAssessmentEditingPermission &&
                                                            canEdit && <Stack
                                                                    direction={{ base: 'column', sm: 'row' }}
                                                                    spacing={3}
                                                                    align={{ base: 'stretch', md: 'center' }}
                                                                >
                                                                    <Button
                                                                        variant="outline"
                                                                        borderColor={version?.ravstate === '0' ? "orange.500" : "green.500"}
                                                                        bg="transparent"
                                                                        width="full"
                                                                        size="sm"
                                                                        color={getColor()}
                                                                        _hover={{ bg: "whiteAlpha.800" }}
                                                                        onClick={() => {
                                                                            navigateTo(`/riskassessments/edit/${riskAssessment.raid}`, {
                                                                                state: {
                                                                                    step: 1,
                                                                                    isReview: true
                                                                                }
                                                                            });
                                                                        }}
                                                                    >
                                                                        <HStack alignItems="center">
                                                                            <Icon
                                                                                as={BsPencil}
                                                                                color={version?.ravstate === '0' ? "orange.500" : "green.500"}
                                                                            />
                                                                            <Text fontSize={{ base: "sm", md: "sm" }} fontWeight="md">
                                                                                Continue  {version?.ravstate === '0' ? "Editing" : "Review"}
                                                                            </Text>
                                                                        </HStack>
                                                                    </Button>
                                                                </Stack>
                                                            }

                                                            {
                                                                version?.ravstate === '1' && 
                                                                riskAssessment?.currentversion === null &&
                                                                hasRiskAssessmentEditingPermission &&
                                                            canEdit && <Stack
                                                                    direction={{ base: 'column', sm: 'row' }}
                                                                    spacing={3}
                                                                    align={{ base: 'stretch', md: 'center' }}
                                                                >
                                                                    <Button
                                                                        variant="outline"
                                                                        borderColor={"green.500"}
                                                                        bg="transparent"
                                                                        width="full"
                                                                        size="sm"
                                                                        color={getColor()}
                                                                        _hover={{ bg: "whiteAlpha.800" }}
                                                                        onClick={createNewReview}
                                                                    >
                                                                        <HStack alignItems="center">
                                                                            <Icon
                                                                                as={BsPlusCircle}
                                                                                color={"green.500"}
                                                                            />
                                                                            <Text fontSize={{ base: "sm", md: "sm" }} fontWeight="md">
                                                                                Create New Review
                                                                            </Text>
                                                                        </HStack>
                                                                    </Button>
                                                                </Stack>
                                                            }
                                                        </Stack>
                                                    </Box>
                                                </GridItem>
                                        }

                                        <GridItem colSpan={4}>
                                            <VStack w="full" alignItems="baseline">
                                                <HStack w="full" gap={1}>
                                                    {
                                                        !isMobile && <>
                                                            <Image src={miniLogoWhite} h={{ base: "70px", md: "100px" }} />

                                                            <Divider
                                                                orientation="vertical"
                                                                color="white"
                                                                h="100px"
                                                                ml={8}
                                                            />
                                                        </>
                                                    }

                                                    {
                                                        !isMobile &&
                                                        <Box pr={3}>
                                                            <SlideFade in={!firstTimeLoading} style={{ width: "100%" }}>
                                                                <Text
                                                                    fontSize={{ base: "3xl", lg: "30pt" }}
                                                                    fontWeight={600}
                                                                    color="white"
                                                                    mt={isMobile ? 0 : -3}
                                                                >
                                                                    {riskAssessment?.raname}
                                                                </Text>
                                                            </SlideFade>

                                                            <HStack color="whiteAlpha.700">
                                                                <Text
                                                                    fontSize={{ base: "md", lg: "lg" }}
                                                                    fontWeight={600}
                                                                    ml="2px"
                                                                >
                                                                    RISK ASSESSMENT
                                                                </Text>

                                                                {
                                                                    version.ravstate === '0' && <Badge
                                                                        variant="outline"
                                                                        color="white"
                                                                        colorScheme="whiteAlpha"
                                                                    >
                                                                        <Icon
                                                                            as={BsPencil}
                                                                            mt="-2px"
                                                                            mr="2px"
                                                                            verticalAlign="middle"
                                                                        /> Draft</Badge>
                                                                }
                                                                {
                                                                    version.ravstate === '1' && <Badge
                                                                        variant="outline"
                                                                        color="white"
                                                                        colorScheme="whiteAlpha"
                                                                    >
                                                                        <Icon
                                                                            as={BsCheckCircle}
                                                                            mt="-2px"
                                                                            mr="2px"
                                                                            verticalAlign="middle"
                                                                        /> Current</Badge>
                                                                }
                                                                {
                                                                    version.ravstate === '2' && <Badge
                                                                        variant="outline"
                                                                        color="white"
                                                                        colorScheme="whiteAlpha"
                                                                    >
                                                                        <Icon
                                                                            as={BsArchive}
                                                                            mt="-2px"
                                                                            mr="2px"
                                                                            verticalAlign="middle"
                                                                        /> Archived</Badge>
                                                                }
                                                                {
                                                                    version.ravstate === '99' && <Badge
                                                                        variant="outline"
                                                                        color="white"
                                                                        colorScheme="whiteAlpha"
                                                                    >
                                                                        <Icon
                                                                            as={BsTrash}
                                                                            mt="-2px"
                                                                            mr="2px"
                                                                            verticalAlign="middle"
                                                                        /> Deleted</Badge>
                                                                }
                                                            </HStack>
                                                        </Box>
                                                    }

                                                    {
                                                        isMobile && <>
                                                            <VStack alignItems="start" w="100%">
                                                                {
                                                                    canEdit &&
                                                                    riskAssessment?.publishedversion === null &&
                                                                    (version?.ravstate !== '2' || version?.ravstate !== '99') &&
                                                                    <Button
                                                                        w="100%"
                                                                        colorScheme="white"
                                                                        variant="solid"
                                                                        size="sm"
                                                                        mt={6}
                                                                        onClick={() => {
                                                                            setSelectedAcknowledgementTeams(riskAssessment?.teams);
                                                                            setIsControlModalOpen(true);
                                                                        }}
                                                                    >
                                                                        <HStack color="green.500">
                                                                            <Icon as={BsPencil} />
                                                                            <Text fontWeight={500}>Edit Details</Text>
                                                                        </HStack>
                                                                    </Button>
                                                                }

                                                                {
                                                                    acknowledgement?.raadateacknowledged != null && <HStack color="white">
                                                                        <Icon as={BsCheckCircleFill} />
                                                                        <Text
                                                                            fontSize="sm"
                                                                            fontWeight={500}
                                                                        >
                                                                            Acknowledged {localDate(acknowledgement?.raadateacknowledged)}
                                                                        </Text>
                                                                    </HStack>
                                                                }
                                                            </VStack>
                                                        </>
                                                    }

                                                    {
                                                        !isMobile && <>
                                                            <Spacer />

                                                            <VStack textAlign="right" minW="150px">
                                                                <SlideFade in={!firstTimeLoading} style={{ width: "100%" }}>
                                                                    <Text
                                                                        fontSize="sm"
                                                                        fontWeight={500}
                                                                        color="white"
                                                                        mt={1}
                                                                    >
                                                                        Version {version.ravsequencenumber}
                                                                    </Text>

                                                                    <Text
                                                                        fontSize="sm"
                                                                        fontWeight={500}
                                                                        color="white"
                                                                        mt={1}
                                                                    >
                                                                        {
                                                                            !isNull(riskAssessment?.publishedversion?.ravdatecreated) && version?.ravstate !== "0" ?
                                                                                `Published ${localDate(riskAssessment?.publishedversion?.ravdatecreated)}` :
                                                                                `Created ${localDate(riskAssessment?.currentversion?.ravdatecreated)}`
                                                                        }
                                                                    </Text>
                                                                </SlideFade>

                                                                {
                                                                    acknowledgement?.raadateacknowledged != null && <HStack color="white">
                                                                        <Icon as={BsCheckCircleFill} />
                                                                        <Text
                                                                            fontSize="sm"
                                                                            fontWeight={500}
                                                                        >
                                                                            Acknowledged {localDate(acknowledgement?.raadateacknowledged)}
                                                                        </Text>
                                                                    </HStack>
                                                                }

                                                                {
                                                                    canEdit &&
                                                                    (version?.ravstate !== '2' || version?.ravstate !== '99') && <Button
                                                                        alignSelf="stretch"
                                                                        colorScheme="white"
                                                                        variant="solid"
                                                                        size="xs"
                                                                        onClick={() => {
                                                                            getTeamsToAcknowledge(); // NEED THIS

                                                                            setIsControlModalOpen(true);
                                                                        }}
                                                                    >
                                                                        <HStack color="green.500">
                                                                            <Icon as={BsPencil} />
                                                                            <Text fontWeight={600}>Edit Details</Text>
                                                                        </HStack>
                                                                    </Button>
                                                                }
                                                            </VStack>
                                                        </>
                                                    }
                                                </HStack>
                                            </VStack>
                                        </GridItem>

                                        <GridItem colSpan={{ base: 4, md: 1 }}>
                                            <VStack alignItems="start">
                                                <Text
                                                    fontWeight={500}
                                                    color="whiteAlpha.800"
                                                >
                                                    Risk Assessment
                                                </Text>

                                                <SkeletonText isLoaded={!loading} noOfLines={1} w="75%">
                                                    <Text
                                                        fontWeight={500}
                                                        fontSize="11pt"
                                                        color="white"
                                                    >
                                                        {riskAssessment?.raname}
                                                    </Text>
                                                </SkeletonText>
                                            </VStack>
                                        </GridItem>

                                        <GridItem colSpan={{ base: 4, md: 1 }}>
                                            <VStack alignItems="start">
                                                <HStack>
                                                    <Icon as={BsExclamationCircle} color="whiteAlpha.800" />
                                                    <Text
                                                        fontWeight={500}
                                                        color="whiteAlpha.800"
                                                    >
                                                        Category
                                                    </Text>
                                                </HStack>

                                                <SkeletonText isLoaded={!loading} noOfLines={1} w="75%">
                                                    <Text
                                                        fontWeight={500}
                                                        fontSize="11pt"
                                                        color="white"
                                                    >
                                                        {riskAssessment?.racategory || "-"}
                                                    </Text>
                                                </SkeletonText>
                                            </VStack>
                                        </GridItem>

                                        <GridItem colSpan={{ base: 4, md: 1 }}>
                                            <VStack alignItems="start">
                                                <HStack>
                                                    <Icon as={BsCalendar2Week} color="whiteAlpha.800" />
                                                    <Text
                                                        fontWeight={500}
                                                        color="whiteAlpha.800"
                                                    >
                                                        Next Review Date
                                                    </Text>
                                                </HStack>

                                                <SkeletonText isLoaded={!loading} noOfLines={1} w="75%">
                                                    <Text
                                                        fontWeight={500}
                                                        fontSize="11pt"
                                                        color="white"
                                                    >
                                                        {localDate(riskAssessment?.raexpirydate) || "-"}
                                                    </Text>
                                                </SkeletonText>
                                            </VStack>
                                        </GridItem>

                                        <GridItem
                                            w="full"
                                            rowSpan={2}
                                            colSpan={{ base: 4, md: 1 }}
                                        >
                                            <VStack alignItems="start" w="full">
                                                <HStack>
                                                    <Icon as={BsPeople} color="whiteAlpha.800" />
                                                    <Text
                                                        fontWeight={500}
                                                        color="whiteAlpha.800"
                                                    >
                                                        Teams to Acknowledge
                                                    </Text>
                                                </HStack>

                                                <SlideFade in={!firstTimeLoading} style={{ width: "100%" }}>
                                                    {
                                                        riskAssessment?.teams.length > 0 && <VStack
                                                            alignItems="start"
                                                            bg="whiteAlpha.900"
                                                            p={2}
                                                            w="100%"
                                                            rounded="lg"
                                                        >
                                                            {
                                                                riskAssessment?.teams?.map((t: any, index: any) => {
                                                                    return <HStack key={index} w="full">
                                                                        <Avatar
                                                                            name={t.teamname}
                                                                            size="xs"
                                                                            color="white"
                                                                        />
                                                                        <Text
                                                                            fontWeight={500}
                                                                            fontSize="10pt"
                                                                            color="gray.600"
                                                                        >
                                                                            {t.teamname}
                                                                        </Text>
                                                                    </HStack>
                                                                })
                                                            }
                                                        </VStack>
                                                    }
                                                </SlideFade>
                                            </VStack>
                                        </GridItem>

                                        <GridItem colSpan={2}>
                                            <VStack alignItems="start">
                                                <Text
                                                    fontWeight={500}
                                                    color="whiteAlpha.800"
                                                >
                                                    Description
                                                </Text>

                                                <SkeletonText isLoaded={!loading} noOfLines={4} w="100%">
                                                    <Text
                                                        fontWeight={500}
                                                        fontSize={{ base: "xs", md: "11pt" }}
                                                        color="white"
                                                    >
                                                        {riskAssessment?.radescription || "N/A"}
                                                    </Text>
                                                </SkeletonText>
                                            </VStack>
                                        </GridItem>
                                    </Grid>

                                    <HStack
                                        w="full"
                                        pt={3}
                                        px={{ base: 3, md: 0 }}
                                    >
                                        <VStack alignItems="baseline">
                                            <Text
                                                fontWeight={{ base: 600, lg: 700 }}
                                                fontSize="2xl"
                                                textAlign="left"
                                                color="gray.700"
                                            >
                                                Hazards
                                            </Text>

                                            <Text color="gray.600" fontSize={{ base: "xs", md: "sm" }}>
                                                Please read the list of hazards linked to this assessment. Understanding these risks will help keep you and your colleagues safe.
                                            </Text>
                                        </VStack>

                                        <Spacer />

                                        <Image
                                            src={hazardImg}
                                            h={{ base: "75px", md: "100px" }}
                                            pt={2}
                                        />
                                    </HStack>

                                    <Box
                                        w="full"
                                        pt={3}
                                        bg="whiteAlpha.400"
                                        roundedBottom="lg"
                                    >
                                        {
                                            isEmpty(version?.hazards) ? <Center my={5} w="full">
                                                <VStack gap={2}>
                                                    <Box textAlign="center">
                                                        <Text
                                                            fontSize="lg"
                                                            fontWeight={700}
                                                            mb={2}
                                                        >
                                                            No Hazards
                                                        </Text>

                                                        <Text
                                                            fontSize="sm"
                                                            fontWeight={400}
                                                            color="gray.500"
                                                        >
                                                            No Hazards have been added to this Risk Assessment
                                                        </Text>
                                                    </Box>
                                                </VStack>
                                            </Center> :

                                                <VStack gap={{ base: 0, md: 2 }} pt={2}>
                                                    {
                                                        version?.hazards.map((h: any) => {
                                                            return <SlideFade in={!firstTimeLoading} style={{ width: "100%" }}>
                                                                <HazardReader
                                                                    key={h.rahid}
                                                                    riskAssessmentId={riskAssessment.raid}
                                                                    riskAssessmentVersionId={riskAssessment.currentversion?.ravid}
                                                                    hazard={h}
                                                                    publishing={true}
                                                                />
                                                            </SlideFade>
                                                        })
                                                    }
                                                </VStack>
                                        }
                                    </Box>

                                    {
                                        riskAssessment?.acknowledgementrequired && <Box
                                            bgGradient={firstTimeLoading ? "white" : 'linear(to-r, green.500, green.400)'}
                                            roundedBottom={{ base: "none", md: "lg" }}
                                            mx={{ base: 0, md: -5 }}
                                            px={{ base: 3, md: 5 }}
                                            pb={{ base: 8, md: 4 }}
                                            pt={2}
                                        >
                                            <VStack alignItems="start" gap={1}>
                                                <Text
                                                    fontSize={{ base: "lg", lg: "xl" }}
                                                    fontWeight={600}
                                                    color="white"
                                                >
                                                    Review and Acknowledge
                                                </Text>

                                                <Text
                                                    fontSize={{ base: "xs", lg: "md" }}
                                                    fontWeight={500}
                                                    color="white"
                                                    w={{ base: "100%", md: "75%" }}
                                                >
                                                    {user.forename}, please review the information above carefully. By clicking the button below, you acknowledge that you have read and understood this risk assessment.
                                                </Text>

                                                <Button
                                                    w={{ base: "100%", md: "unset" }}
                                                    size={isMobile ? "md" : "sm"}
                                                    colorScheme="white"
                                                    color="green.500"
                                                    _hover={{
                                                        bg: "green.500",
                                                        color: "white"
                                                    }}
                                                    onClick={() => { setIsAcknowledgeModalOpen(true); }}
                                                >
                                                    <HStack>
                                                        <Icon as={BsHandThumbsUp} />
                                                        <Text>Confirm</Text>
                                                    </HStack>
                                                </Button>
                                            </VStack>
                                        </Box>
                                    }
                                </Box>
                            </VStack>
                        </TabPanel>

                        {
                            !isDraftVersion && <TabPanel>
                                <Acknowledgements
                                    version={version}
                                    riskAssessment={riskAssessment} />
                            </TabPanel>
                        }

                        {
                            onEditingTeam && <TabPanel>
                                <Versions
                                    version={version}
                                    riskAssessment={riskAssessment} />
                            </TabPanel>
                        }
                    </TabPanels>
                </Tabs>
            </Box>

            {renderAddControlModal()}
            {renderAcknowledgeModal()}
        </Box>
    );
};