import { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Avatar,
	Badge,
	Box,
	Button,
	Divider,
	Flex,
	FormControl,
	Grid,
	HStack,
	Icon,
	Input,
	Select,
	SlideFade,
	Spacer,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useBreakpointValue,
	useColorModeValue,
	VStack,
} from "@chakra-ui/react";
import {
	BsArrowLeftCircleFill,
	BsWrench,
	BsClockFill,
	BsExclamationCircleFill,
	BsPlusSquare,
	BsXCircleFill,
} from "react-icons/bs";

import { InsightActionCard } from "../common/InsightActionCard";

import critical from "../../assets/images/incident.svg";
import blocked from "../../assets/images/blocked.svg";
import overdue from "../../assets/images/waiting.svg";
import assignedToMe from "../../assets/images/confirm.svg";

import { CompletedActionsTable } from "./CompletedActionsTable";
import { getActions } from "../../features/actions/actions.actions";
import { SmallFilterCard } from "../common/SmallFilterCard";
import { isEmpty, isNull, isUndefined } from "lodash";
import { TeamCombobox } from "../common/TeamCombobox";
import { localDate } from "../../helpers/DayJsHelper";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { ContactSalesModal } from "../common/ContactSalesModal";
import { CheckIsWithinProductTierLimit, CheckProductTierFlag } from "../../helpers/Utilities";

export const Actions = () => {
	const { state }: any = useLocation();
	const navigateTo = useNavigate();
	const isMobile = useBreakpointValue({ base: true, xl: false });

	const [tabIndex, setTabIndex] = useState(0);

	const [criticalCount, setCriticalCount] = useState(0);
	const [overdueCount, setOverdueCount] = useState(0);
	const [assignedToMeCount, setAssignedToMeCount] = useState(0);
	const [unassignedCount, setUnassignedCount] = useState(0);

	const [notStartedCount, setNotStartedCount] = useState(0);
	const [ongoingCount, setOngoingCount] = useState(0);
	const [blockedCount, setBlockedCount] = useState(0);

	const [firstTimeLoading, setFirstTimeLoading] = useState(true);
	const [loading, setLoading] = useState<boolean>(false);

	const [newActions, setNewActions] = useState([]);
	const [inProgressActions, setInProgressActions] = useState([]);
	const [blockedActions, setBlockedActions] = useState([]);
	const [completedActions, setCompletedActions] = useState([]);

	const [completedPageCount, setCompletedPageCount] = useState(0);
	const [completedPageIndex, setCompletedPageIndex] = useState(0);

	const [newPageCount, setNewPageCount] = useState(0);
	const [newPageIndex, setNewPageIndex] = useState(0);

	const [inProgressPageCount, setInProgressPageCount] = useState(0);
	const [inProgressPageIndex, setInProgressPageIndex] = useState(0);

	const [blockedPageCount, setBlockedPageCount] = useState(0);
	const [blockedPageIndex, setBlockedPageIndex] = useState(0);

	const [categories, setCategories] = useState([]);
	const [teams, setTeams] = useState([]);

	const [filter, setFilter] = useState<string>(
		state !== null && state.filter ? state.filter : ""
	);

	const [isManager, setIsManager] = useState(false);
	const [team, setTeam] = useState(0);
	const [category, setCategory] = useState("");
	const [search, setSearch] = useState("");
	const [filterSelected, setFilterSelected] = useState(false);

	const [salesModalisOpen, setSalesModalIsOpen] = useState(false);
	const [isWithinProductTierLimit, setIsWithinProductTierLimit] = useState(true);

	const user = useSelector((state: RootState) => state.authReducer.user);
	const hasActionsEditingPermission = CheckProductTierFlag(user?.producttier?.ptactioneditingflag);

	const fetchPage = async (state: string) => {
		let stateData = { type: state, index: "0" };

		switch (state) {
			case "notstarted": stateData.index = (newPageIndex + 1).toString();
				break;
			case "ongoing": stateData.index = (inProgressPageIndex + 1).toString();
				break;
			case "blocked": stateData.index = (blockedPageIndex + 1).toString();
				break;
		};

		await getActions("", filter, state, team.toString(), category, search, stateData.index)
			.then((res: any) => {
				let updatedActions = [];

				switch (stateData.type) {
					case "notstarted":
						updatedActions = newActions.concat(res.actions);

						setNewActions(updatedActions);
						setNewPageIndex(res.page);
						break;
					case "ongoing":
						updatedActions = inProgressActions.concat(res.actions);

						setInProgressActions(updatedActions);
						setInProgressPageIndex(res.page);
						break;
					case "blocked":
						updatedActions = blockedActions.concat(res.actions);

						setBlockedActions(updatedActions);
						setBlockedPageIndex(res.page);
						break;
				};
			})
			.catch((error: any) => {
				console.log(error);
			});
	};

	const fetchCompletedActions = useCallback(
		async ({ completedPageIndex }) => {
			setLoading(true);

			await getActions("", "", "completed", "", "", "", (completedPageIndex + 1).toString())
				.then(async (res: any) => {
					const formattedActionsData = res.actions.map((a: any) => {
						return {
							id: a.actionid,
							title: a.actiondescription || "Untitled",
							dateClosed: a.actiondatelastmodified,
							closedBy: `${a.modforename} ${a.modsurname}`
						};
					});

					setCompletedActions(formattedActionsData);
					setCompletedPageCount(res.pageCount);
				})
				.catch((error: any) => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}, []);

	useEffect(() => { fetchCompletedActions({ completedPageIndex }); },
		[fetchCompletedActions, completedPageIndex]);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			try {
				await getActions("", filter, "notstarted", team.toString(), decodeURIComponent(category), search, (newPageIndex + 1).toString())
					.then(async (res: any) => {
						setCategories(res.availableCategories);
						setIsManager(!isEmpty(res.teamsManaged));

						const teamsManaged = res.teamsManaged;
						let teamMemberships = res.teamMemberships;

						for (let i = 0; i < teamsManaged.length; i++) {
							const currentTeam = teamsManaged[i];

							for (let j = 0; j < teamMemberships.length; j++) {
								if (currentTeam.teamid === teamMemberships[j].teamid) {
									teamMemberships.splice(j, 1);
								}
							}
						}
						setTeams(teamsManaged.concat(teamMemberships));

						setCriticalCount(res.criticalCount);
						setOverdueCount(res.overdueCount);
						setAssignedToMeCount(res.assignedToMeCount);
						setUnassignedCount(res.unassignedCount);

						setNewPageIndex(res.page);
						setNewPageCount(res.pageCount);
						setNewActions(res.actions);

						setNotStartedCount(res.actionCount);

						setIsWithinProductTierLimit(CheckIsWithinProductTierLimit(res.orgActionCount, user?.producttier?.ptactionlimit));
					});

				await getActions("", filter, "ongoing", team.toString(), decodeURIComponent(category), search, (inProgressPageIndex + 1).toString())
					.then(async (res: any) => {
						setInProgressActions(res.actions);
						setInProgressPageCount(res.pageCount);
						setInProgressPageIndex(res.page);

						setOngoingCount(res.actionCount);
					});

				await getActions("", filter, "blocked", team.toString(), decodeURIComponent(category), search, (blockedPageIndex + 1).toString())
					.then(async (res: any) => {
						setBlockedActions(res.actions);
						setBlockedPageCount(res.pageCount);
						setBlockedPageIndex(res.page);

						setBlockedCount(res.actionCount);
					});
			} catch (error: any) {
				console.log(error);
			} finally {
				setLoading(false);
				setFirstTimeLoading(false);
			}
		}

		fetchData();
	}, [filter, team, category, search]);

	useEffect(() => {
		const filterInEffect =
			(filter !== "" && filter !== "all") ||
			(team !== 0 && team !== undefined) ||
			search !== "" ||
			(category !== "" && category !== undefined);

		setFilterSelected(filterInEffect);
	}, [filter, team, category, search]);

	function setClearAllFiltersPressed() {
		setFilter("all");
		setNewPageIndex(0);
		setInProgressPageIndex(0);
		setBlockedPageIndex(0);
		setSearch("");
		setCategory("");
		setTeam(0);
	}

	const renderActionCard = (item: any) => {
		const isDanger = item.isBlocked || item.overdue || item.isCritical;
		const isWarning = item.nearlydue;
		const username = isNull(item.userforename) ? "" : `${item.userforename} ${item.usersurname}`;

		return <Box
			key={`action_${item.actionid}`}
			className="hover-pop"
			cursor="pointer"
			w="100%"
			h="auto"
			bg={isDanger ? "red.50" : isWarning ? "orange.50" : "white"}
			boxShadow={"sm"}
			rounded={"sm"}
			onClick={() => navigateTo(`/actions/action/${item.actionid}`)}
		>
			<Box
				h="2px"
				mb={2}
				bg={isDanger ? "danger.500" : isWarning ? "orange.400" : "gray.400"}
				borderTopLeftRadius="3px"
				borderTopRightRadius="3px"
			/>

			<Box m={3} mt={0}>
				<VStack alignItems="start" gap={0}>
					<HStack>
						<Text
							textAlign="start"
							fontWeight={700}
							fontSize="sm"
							color="gray.600"
							wordBreak="break-all"
							textOverflow="ellipsis"
						>
							{
								item.isCritical && <><Icon
									as={BsExclamationCircleFill}
									color="danger.500"
									fontSize="xs"
								/>&nbsp;</>
							}
							{item.description || "Untitled"}
						</Text>
					</HStack>

					<Text
						hidden={isNull(item.actionduedate)}
						textAlign="start"
						fontWeight={400}
						fontSize="xs"
						color="gray.600"
					>
						Due {localDate(item.actionduedate)}
					</Text>

					<HStack hidden={!item.overdue && !item.nearlydue}>
						<Badge
							float="left"
							size="sm"
							colorScheme={item.overdue ? "danger" : "orange"}
							variant="outline"
						>
							<HStack>
								<Icon as={item.overdue ? BsExclamationCircleFill : BsClockFill} />
								<Text>
									{item.overdue ? "Overdue" : "Due Soon"}
								</Text>
							</HStack>
						</Badge>
					</HStack>
				</VStack>

				<HStack mt={3}>
					<Avatar
						size="xs"
						color="white"
						name={item.unassigned && isEmpty(username) && isEmpty(item.teamname) ? "" : username || item.teamname}
					/>
					<Text
						textAlign="start"
						fontWeight={500}
						fontSize="sm"
						color="gray.600"
					>
						{item.unassigned && isEmpty(username) && isEmpty(item.teamname) ? "Unassigned" : username || item.teamname}
					</Text>
				</HStack>
			</Box>
		</Box>
	};

	return (
		<Box m={{ base: 0, md: 5 }}>
			<Button
				display={{ base: "none", md: "unset" }}
				w="fit-content"
				mb={2}
				hidden={isUndefined(state?.from)}
				size="sm"
				variant="outline"
				color="gray.600"
				fontWeight={600}
				boxShadow="xs"
				onClick={() => {
					navigateTo(-1);
				}}
			>
				<HStack>
					<Icon as={BsArrowLeftCircleFill} />
					<Text>{state?.from === "home" ? "Back to Home" : state?.from === "notification" && "Back to Notification"}</Text>
				</HStack>
			</Button>

			<Box
				px={{ base: 0, md: 5 }}
				pt={5}
				pb={3}
				bg={useColorModeValue("white", "gray.900")}
				boxShadow={"lg"}
				rounded={"lg"}
				minH={{ base: "100vh", md: "unset" }}
				height={{ base: "100%", md: "unset" }}
			>
				<Button
					display={{ base: "unset", md: "none" }}
					hidden={isUndefined(state?.from)}
					size="sm"
					m={5}
					textAlign="start"
					color="gray.600"
					variant="link"
					fontWeight={600}
					onClick={() => {
						navigateTo(-1);
					}}
				>
					<HStack>
						<Icon as={BsArrowLeftCircleFill} />
						<Text>{state?.from === "home" ? "Back to Home" : state?.from === "notification" && "Back to Notification"}</Text>
					</HStack>
				</Button>

				<HStack mb={5} mx={5} gap={4}>
					<Icon
						as={BsWrench}
						verticalAlign="middle"
						fontSize="4xl"
						color="green.500"
					/>
					<Text
						fontWeight={{ base: 600, lg: 700 }}
						fontSize={{ base: "lg", lg: "2xl" }}
						color="gray.700"
					>
						Actions
					</Text>

					<Spacer />

					<VStack>
						{
							isManager && <Button
								display={{ base: "block", md: "none" }}
								bg="brand.500"
								color="white"
								size="sm"
								_hover={{
									bg: "#248451"
								}}
								onClick={() => {
									if (hasActionsEditingPermission && isWithinProductTierLimit) {
										navigateTo("/actions/create",
											{ state: { create: true } });
									}
									else {
										setSalesModalIsOpen(true);
									}
								}}
							>
								<HStack>
									<Icon as={BsPlusSquare} />
									<Text>New Action</Text>
								</HStack>
							</Button>
						}

						{
							filterSelected && <Button
								variant="ghost"
								size="xs"
								onClick={setClearAllFiltersPressed}
							>
								<HStack>
									<Icon as={BsXCircleFill} color="gray.600" fontSize="sm" />
									<Text>Clear All Filters</Text>
								</HStack>
							</Button>
						}
					</VStack>
				</HStack>

				<Divider w="unset" mx={{ base: 0, md: -5 }} />

				<Tabs
					colorScheme="green"
					onChange={(index) => setTabIndex(index)}
				>
					<Flex
						justify={{ base: "center", md: "space-between" }}
						py={2}
					>
						<TabList
							mx={{ base: 0, md: 5 }}
							w={{ base: "full", md: "unset" }}
							justifyContent="center"
						>
							<Tab>
								<Text
									color={tabIndex === 0 ? "green.500" : "gray.500"}
									fontWeight={tabIndex === 0 ? 700 : 500}
								>
									Active
								</Text>
							</Tab>
							<Tab>
								<Text
									color={tabIndex === 1 ? "green.500" : "gray.500"}
									fontWeight={tabIndex === 1 ? 700 : 500}
								>
									Completed
								</Text>
							</Tab>
						</TabList>

						<HStack
							hidden={!isManager}
							mr={5}
							display={{ base: "none", md: "flex" }}
						>
							<Button
								onClick={() => {
									if (hasActionsEditingPermission && isWithinProductTierLimit) {
										navigateTo("/actions/create",
											{ state: { create: true } });
									}
									else {
										setSalesModalIsOpen(true);
									}
								}}
								leftIcon={<Icon as={BsPlusSquare} />}
								bg="brand.500"
								_hover={{
									bg: "#248451",
								}}
								mb={-2}
								size="sm"
								color="white"
								float="right"
							>
								Create New Action
							</Button>
						</HStack>
					</Flex>

					<TabPanels>
						<TabPanel>
							<Fragment>
								<Grid
									templateColumns={{
										base: "repeat(2, 2fr)",
										md: "repeat(4, 1fr)",
									}}
									gap={5}
									mb={5}
								>
									<InsightActionCard
										title="Critical Actions"
										count={criticalCount || 0}
										image={critical}
										color="danger.500"
										active={filter === "critical"}
										className="hover-pop"
										cursor="pointer"
										onClickAction={() => {
											if (filter === "critical") {
												setFilter("all");

												setNewPageIndex(0);
												setInProgressPageIndex(0);
												setBlockedPageIndex(0);
											} else {
												setFilter("critical");

												setNewPageIndex(0);
												setInProgressPageIndex(0);
												setBlockedPageIndex(0);
											}
										}}
										loading={firstTimeLoading}
									/>

									<InsightActionCard
										title="Overdue Actions"
										count={overdueCount || 0}
										image={overdue}
										color="orange.500"
										active={filter === "overdue"}
										className="hover-pop"
										cursor="pointer"
										onClickAction={() => {
											if (filter === "overdue") {
												setFilter("all");

												setNewPageIndex(0);
												setInProgressPageIndex(0);
												setBlockedPageIndex(0);
											} else {
												setFilter("overdue");

												setNewPageIndex(0);
												setInProgressPageIndex(0);
												setBlockedPageIndex(0);
											}
										}}
										loading={firstTimeLoading}
									/>

									<InsightActionCard
										title="Assigned to me"
										count={assignedToMeCount || 0}
										image={assignedToMe}
										color="#805AD5"
										active={filter === "assignedtome"}
										className="hover-pop"
										cursor="pointer"
										onClickAction={() => {
											if (filter === "assignedtome") {
												setFilter("all");

												setNewPageIndex(0);
												setInProgressPageIndex(0);
												setBlockedPageIndex(0);
											} else {
												setFilter("assignedtome");

												setNewPageIndex(0);
												setInProgressPageIndex(0);
												setBlockedPageIndex(0);
											}
										}}
										loading={firstTimeLoading}
									/>

									<InsightActionCard
										title="Unassigned Actions"
										count={unassignedCount}
										image={blocked}
										color="gray.500"
										active={filter === "unassigned"}
										className="hover-pop"
										cursor="pointer"
										onClickAction={() => {
											if (filter === "unassigned") {
												setFilter("all");

												setNewPageIndex(0);
												setInProgressPageIndex(0);
												setBlockedPageIndex(0);
											} else {
												setFilter("unassigned");

												setNewPageIndex(0);
												setInProgressPageIndex(0);
												setBlockedPageIndex(0);
											}
										}}
										loading={firstTimeLoading}
									/>
								</Grid>

								{
									!isMobile && <Divider w="unset" mx={{ base: 0, md: -10 }} />
								}

								<SlideFade in={!firstTimeLoading} offsetY="20px">
									{
										categories.length > 0 && categories.length < 7 &&
										<Box
											p={1.5}
											mt={5}
											bg="gray.50"
											textAlign="left"
											rounded="lg"
											border="1px solid"
											borderColor="gray.100"
										>
											<Text
												fontWeight={500}
												color="gray.500"
												fontSize="sm"
												ml={1}
												mb={2}
											>
												Action Categories
											</Text>

											<SlideFade in={!firstTimeLoading}>
												{
													categories.map((c: any, i: Number) => {
														return (
															<SmallFilterCard
																key={i}
																title={c.actioncategory}
																onClickAction={() => {
																	setNewPageIndex(0);
																	setInProgressPageIndex(0);
																	setBlockedPageIndex(0);

																	c.actioncategory !== category
																		? setCategory(c.actioncategory)
																		: setCategory("");
																}}
																selectedCard={category}
																icon={BsWrench}
																iconColor="brand.500"
															/>
														);
													})
												}
											</SlideFade>
										</Box>
									}
								</SlideFade>

								<SlideFade in={!firstTimeLoading} offsetY="20px">
									<Stack
										direction={{ base: "column", lg: "row" }}
										mt={
											categories.length > 0 && categories.length <= 7 ? 2 :
												categories.length > 7 ? 5 : 5
										}
									>
										{
											categories.length > 7 ?
												<FormControl colorScheme="green" w={{ base: "full", lg: "50%" }}>
													<Select
														_focus={{ borderColor: "brand.500", borderWidth: "1px" }}
														color="gray.600"
														fontSize="sm"
														defaultValue={
															state !== null && state.teamId ? Number(state.teamId) : ""
														}
														onChange={(e) => {
															setNewPageIndex(0);
															setInProgressPageIndex(0);
															setBlockedPageIndex(0);

															setCategory(e.target.value);
														}}
														placeholder="Search by Category"
													>
														{
															categories.map((c: any, i) => {
																return <option key={i} value={c.actioncategory}>{c.actioncategory}</option>;
															})
														}
													</Select>
												</FormControl> :

												<FormControl w={{ base: "full", lg: "50%" }}>
													<Input
														placeholder="Search Actions"
														rounded="md"
														fontSize="sm"
														value={search}
														onChange={(e: any) => {
															setNewPageIndex(0);
															setInProgressPageIndex(0);
															setBlockedPageIndex(0);

															setSearch(e.target.value);
														}} />
												</FormControl>
										}

										{
											categories.length > 7 &&
											<FormControl w={{ base: "full", lg: "50%" }}>
												<Input
													placeholder="Search Actions"
													rounded="md"
													fontSize="sm"
													value={search}
													onChange={(e: any) => {
														setNewPageIndex(0);
														setInProgressPageIndex(0);
														setBlockedPageIndex(0);

														setSearch(e.target.value);
													}} />
											</FormControl>
										}

										<FormControl colorScheme="green" w={{ base: "full", lg: "50%" }} hidden={isEmpty(teams)}>
											<TeamCombobox
												disabled={isEmpty(teams)}
												teams={teams || []}
												selectedTeam={team}
												update={(value: any) => {
													setNewPageIndex(0);
													setInProgressPageIndex(0);
													setBlockedPageIndex(0);

													setTeam(value);
												}}
											/>
										</FormControl>
									</Stack>
								</SlideFade>

								<Grid
									gridTemplateColumns={{
										base: "repeat(1, minmax(0, 3fr))",
										xl: "repeat(3, minmax(0, 1fr))",
									}}
									gridAutoRows={!isMobile ? "1fr" : ""}
									gap={{ base: 2, md: 5 }}
									mt={5}
									verticalAlign="default"
									hidden={firstTimeLoading}
								>
									<Accordion
										defaultIndex={[0]}
										allowToggle={isMobile}
										allowMultiple
										rounded="lg"
										border="1px solid"
										borderColor="gray.100"
										w="100%"
										h={{ base: "auto", xl: "100%" }}
									>
										<AccordionItem
											border="none"
											bg="gray.50"
											rounded="lg"
											w="100%"
											h={{ base: "unset", xl: "100%" }}
										>
											<AccordionButton
												w="100%"
												pt={{ base: 5, md: 6 }}
												py={{ base: 5, md: 3 }}
												backgroundColor="gray.50"
												_hover={{ bg: "" }}
												border="none"
												roundedTop="lg"
											>
												<Box w="100%" textAlign={{ base: "start", md: "center" }}>
													<Text
														fontWeight={700}
														color="gray.700"
														display="inline"
														mr={2}
													>
														Not Started
													</Text>

													<Badge
														colorScheme="facebook"
														verticalAlign="text-top"
														display="inline"
													>
														{notStartedCount}
													</Badge>
												</Box>

												<Spacer />

												<AccordionIcon display={{ base: "block", md: "none" }} />
											</AccordionButton>

											<AccordionPanel
												w="full"
												h="auto"
												pb={2}
												roundedBottom="lg"
												backgroundColor="gray.50"
											>
												<VStack mb={3}>
													{newActions.map((item: any) => renderActionCard(item))}
												</VStack>

												<Button
													w="100%"
													size="sm"
													my={2}
													hidden={
														!(newPageIndex < newPageCount) ||
														isEmpty(newActions) ||
														loading
													}
													onClick={() => {
														fetchPage("notstarted");
													}}
												>
													Load more actions
												</Button>
											</AccordionPanel>
										</AccordionItem>
									</Accordion>

									<Accordion
										defaultIndex={[0]}
										allowToggle={isMobile}
										allowMultiple
										rounded="lg"
										border="1px solid"
										borderColor="gray.100"
										w="100%"
										h={{ base: "auto", xl: "100%" }}
									>
										<AccordionItem
											border="none"
											bg="gray.50"
											rounded="lg"
											w="100%"
											h={{ base: "unset", xl: "100%" }}
										>
											<AccordionButton
												w="100%"
												pt={{ base: 5, md: 6 }}
												py={{ base: 5, md: 3 }}
												backgroundColor="gray.50"
												_hover={{ bg: "" }}
												border="none"
												roundedTop="lg"
											>
												<Box w="100%" textAlign={{ base: "start", md: "center" }}>
													<Text
														fontWeight={700}
														color="gray.700"
														display="inline"
														mr={2}
													>
														In Progress
													</Text>

													<Badge
														colorScheme="facebook"
														verticalAlign="text-top"
														display="inline"
													>
														{ongoingCount}
													</Badge>
												</Box>

												<Spacer />

												<AccordionIcon display={{ base: "block", md: "none" }} />
											</AccordionButton>

											<AccordionPanel
												w="full"
												h="auto"
												pb={2}
												roundedBottom="lg"
												backgroundColor="gray.50"
											>
												<VStack mb={3}>
													{inProgressActions.map((item: any) => renderActionCard(item))}
												</VStack>

												<Button
													w="100%"
													my={2}
													size="sm"
													hidden={
														!(inProgressPageIndex < inProgressPageCount) ||
														isEmpty(inProgressActions) ||
														loading
													}
													onClick={() => {
														fetchPage("ongoing");
													}}
												>
													Load more actions
												</Button>
											</AccordionPanel>
										</AccordionItem>
									</Accordion>

									<Accordion
										defaultIndex={[0]}
										allowToggle={isMobile}
										allowMultiple
										rounded="lg"
										border="1px solid"
										borderColor="gray.100"
										display="flex"
										w="100%"
										height={{ base: "auto", xl: "100%" }}
									>
										<AccordionItem
											border="none"
											bg="gray.50"
											rounded="lg"
											w="100%"
											h={{ base: "unset", xl: "100%" }}
										>
											<AccordionButton
												w="100%"
												pt={{ base: 5, md: 6 }}
												py={{ base: 5, md: 3 }}
												backgroundColor="gray.50"
												_hover={{ bg: "" }}
												border="none"
												roundedTop="lg"
											>
												<Box w="100%" textAlign={{ base: "start", md: "center" }}>
													<Text
														fontWeight={700}
														color="gray.700"
														display="inline"
														mr={2}
													>
														Blocked
													</Text>

													<Badge
														colorScheme="facebook"
														verticalAlign="text-top"
														display="inline"
													>
														{blockedCount}
													</Badge>
												</Box>

												<Spacer />

												<AccordionIcon display={{ base: "block", md: "none" }} />
											</AccordionButton>

											<AccordionPanel
												w="full"
												h="auto"
												pb={2}
												roundedBottom="lg"
												backgroundColor="gray.50"
											>
												<VStack mb={3}>
													{blockedActions.map((item: any) => renderActionCard(item))}
												</VStack>

												<Button
													w="100%"
													my={2}
													size="sm"
													hidden={
														!(blockedPageIndex < blockedPageCount) ||
														isEmpty(blockedActions) ||
														loading
													}
													onClick={() => {
														fetchPage("blocked");
													}}
												>
													Load more actions
												</Button>
											</AccordionPanel>
										</AccordionItem>
									</Accordion>
								</Grid>
							</Fragment>
						</TabPanel>

						<TabPanel>
							<Box
								p={3}
								border="1px"
								borderRadius="12px"
								borderColor="gray.200"
							>
								<CompletedActionsTable
									currentItems={completedActions}
									pageCount={completedPageCount}
									pageIndex={completedPageIndex}
									setPageIndex={setCompletedPageIndex}
									loading={loading}
								/>
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>

			<ContactSalesModal
				salesModalisOpen={salesModalisOpen}
				setSalesModalIsOpen={setSalesModalIsOpen}
				title={"Upgrade to Obbi Pro to unlock the full power of Actions"}
				lineOneTitle={"More actions"}
				lineOne="The Actions feature in Obbi provides clubs with automated issue reporting from your checklists, as well as tracking and managing follow-up actions with your team."
				image={critical}
				buttonOneText="Contact Sales"
				buttonOneLink="https://meetings-eu1.hubspot.com/ryan-bell?__hstc=191942773.9148893facbd66f6fc81cc0b9a1e1318.1674738526181.1696412244530.1696429283866.190&__hssc=191942773.4.1696429283866&__hsfp=1747869543&uuid=e194695e-43b6-4c57-b480-e4ce8a16ebcb"
				buttonTwoText="Learn More"
				buttonTwoLink="https://obbi.golf/features/"
				limitReached={!isWithinProductTierLimit}
				limit={user?.producttier?.ptactionlimit}
				limitType={'actions'}
			/>
		</Box>
	);
};
